import React from 'react';

// assets
import styles from './Footer.module.scss';
import discord from '../../../images/icons/discord.svg';
import twitter from '../../../images/icons/twitter.svg';
import songbits from '../../../images/icons/songbits.svg';
import tiktok from '../../../images/icons/tiktok.svg';
import instagram from '../../../images/icons/instagram.svg';

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className='container'>
        <div className={styles.content}>
          <ul className={styles.links}>
            <li>
              <a href={process.env.REACT_APP_SITE_URL} target="_blank" rel="noreferrer">
                <img src={songbits} alt='' />
              </a>
            </li>
          </ul>
          <p className={styles.copyright}>© {new Date().getFullYear()} SongBits Ltd. All Rights Reserved.</p>
          <ul className={styles.social}>
            <li>
              <a href={'https://twitter.com/SongBitsfans'} target="_blank" rel="noreferrer">
                <img src={twitter} alt=''  />
              </a>
            </li>
            <li>
              <a href={'https://discord.gg/GHBH9YhjDU'} target="_blank" rel="noreferrer">
                <img src={discord} alt='' />
              </a>
            </li>
            <li>
              <a href={'https://www.tiktok.com/@songbits.fans'} target="_blank" rel="noreferrer">
                <img src={tiktok} alt='' />
              </a>
            </li>
            <li>
              <a href={'https://www.instagram.com/songbits.fans/'} target="_blank" rel="noreferrer">
                <img src={instagram} alt='' />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}