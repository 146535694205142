import React from "react";

import cls from "./SongModalLayout.module.scss";

const SongModalLayout = ({
  children,
  onClose = () => {},
  title = "",
  footerText = "",
}) => {
  return (
    <div className={cls.wrapper}>
      <div className={cls.modalContent}>
        <div className={cls.header}>
          <p className={cls.title}>{title}</p>
          <button className={cls.closeBtn} onClick={onClose}>
            X
          </button>
        </div>
        <div className={cls.content}>{children}</div>
        <div className={cls.footer}>
          <p className={cls.footerText}>{footerText}</p>
        </div>
      </div>
    </div>
  );
};

export default SongModalLayout;
